import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Link from '../../Link'

interface HeroProps {
  field_hero_title: string
  field_hero_subtitle: string
  field_hero_cta: Array<{
    uri_alias: string
    title: String
  }>
  relationships: {
    field_image: {
      gatsbyImageData: any
    }
  }
}

const Hero: React.FC<HeroProps> = ({
  field_hero_title,
  field_hero_subtitle,
  field_hero_cta,
  relationships,
}: HeroProps) => (
  <div className="relative">
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
        <div className="absolute inset-0">
          <GatsbyImage className="h-full w-full object-cover" image={relationships?.field_image?.gatsbyImageData} alt="People working on laptops" />
          <div className="absolute bg-gradient-to-r from-yellow-300 via-blue-500 to-pink-400 inset-0 mix-blend-multiply" />
        </div>
        <div className="relative text-center px-4 py-16 sm:px-6 sm:py-24 lg:py-24 lg:px-8">
          <h1 className="font-serif text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl text-white ">
            {field_hero_title}
          </h1>
          <p className="mt-6 max-w-lg mx-auto text-xl text-white font-bold sm:max-w-xl">
            {field_hero_subtitle}
          </p>
          <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
            <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
              <Link
                to={field_hero_cta?.[0]?.uri_alias}
                className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-bold rounded-md shadow-sm bg-yellow-300 text-gray-900 bg-opacity-100 hover:bg-opacity-80 sm:px-8"
              >
                {field_hero_cta?.[0]?.title}
              </Link>
              <Link
                to={field_hero_cta?.[1]?.uri_alias}
                className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-bold rounded-md shadow-sm text-gray-700 bg-white sm:px-8"
              >
                {field_hero_cta?.[1]?.title}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export const paragraphHeroFragments = graphql`
  fragment ParagraphHeroFields on paragraph__hero {
    __typename
    id
    field_hero_cta {
      uri_alias
      title
    }
    field_hero_description {
      value
    }
    field_hero_subtitle
    field_hero_title
    relationships {
      field_image {
        gatsbyImageData(width: 960, placeholder: BLURRED)
      }
    }
  }
`

export default Hero
