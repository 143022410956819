import React from 'react'
import { graphql } from 'gatsby'

interface ContentProps {
  field_body: {
    value: string
  }
}

const Hero: React.FC<ContentProps> = ({
  field_body,
}: ContentProps) => (
  <div className="relative h-full text-lg prose max-w-prose px-4 mx-auto text-white">
    <div dangerouslySetInnerHTML={{ __html: field_body?.value }} />
  </div>
)

export const paragraphContentFragments = graphql`
  fragment ParagraphContentFields on paragraph__content {
    __typename
    id
    field_body {
      value
    }
  }
`

export default Hero
