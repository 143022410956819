import React from 'react'
// import { graphql } from 'gatsby'
import ContentListing from '../../ContentListing'

interface ContentListingParagraphProps {
  relationships: {
    field_content: any[]
  }
}

const ContentListingParagraph: React.FC<ContentListingParagraphProps> = (
  { relationships }: ContentListingParagraphProps,
) => (
  <ContentListing items={relationships?.field_content} />
)

export default ContentListingParagraph

// export const paragraphContentListingFragments = graphql`
//   fragment ParagraphContentListingFields on paragraph__content_listing {
//     __typename
//     id
//     relationships {
//       field_content {
//         ...on node__blog {
//           __typename
//           drupal_id
//           created
//           title
//           path {
//             alias
//           }
//           body {
//             time_to_read
//             summary
//           }
//           relationships {
//             field_topic {
//               name
//             }
//             field_author {
//               title
//               path {
//                 alias
//               }
//               relationships {
//                 field_avatar {
//                   gatsbyImageData(width: 40, aspectRatio: 1)
//                 }
//               }
//             }
//           }
//         }
//         ...on node__case_study {
//           __typename
//           drupal_id
//           created
//           title
//           path {
//             alias
//           }
//           body {
//             time_to_read
//             summary
//           }
//           relationships {
//             field_topic {
//               name
//             }
//             field_author {
//               title
//               path {
//                 alias
//               }
//               relationships {
//                 field_avatar {
//                   gatsbyImageData(width: 40, aspectRatio: 1)
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `
