import React, { FormEventHandler, useReducer } from 'react'
import { graphql } from 'gatsby'
import RenderIf from '../../RenderIf'

interface ContactFormState {
  error: any,
  success: boolean,
  loading: boolean,
}

const initialState: ContactFormState = {
  error: null,
  success: null,
  loading: false,
}

const contactFormReducer = (state: ContactFormState, action: { type: string, payload?: any }) => {
  switch (action.type) {
    case 'SUBMIT_SUCCESSFUL':
      return {
        ...state,
        loading: false,
        success: true,
      }

    case 'SUBMIT_FAILED':
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case 'SUBMIT':
      return {
        ...initialState,
        loading: true,
      }

    default:
      return state
  }
}

const ContactForm: React.FC<any> = () => {
  const [state, dispatch] = useReducer(contactFormReducer, initialState)

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target as HTMLFormElement)
    dispatch({ type: 'SUBMIT' })

    const res = await fetch('/api/contact', {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        firstName: formData.get('first-name'),
        lastName: formData.get('last-name'),
        email: formData.get('email'),
        message: formData.get('message'),
      }),
    })

    if (res.status === 200) {
      dispatch({ type: 'SUBMIT_SUCCESSFUL' })
      formData.delete('first-name')
      formData.delete('last-name')
      formData.delete('email')
      formData.delete('message')
    } else {
      const body = await res.json()
      dispatch({ type: 'SUBMIT_FAILED', payload: body })
    }
  }

  return (
    <div className="px-4 overflow-hidden sm:px-6 lg:px-8">
      <div className="relative max-w-xl mx-auto">
        <svg className="absolute left-full transform translate-x-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true">
          <defs>
            <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
        </svg>
        <svg className="absolute right-full bottom-0 transform -translate-x-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true">
          <defs>
            <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
        </svg>
        <div className="md:text-center">
          <h1 className="font-serif text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
            Contact sales 👋
          </h1>
          <p className="mt-4 text-lg leading-6 text-gray-300">
            Don&apos;t hesitate to reach out. Whether you&apos;re looking for consulting,
            team augmentation, digital strategy, design or development. We&apos;ve got you covered!
          </p>
        </div>
        <div className="mt-12">
          <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
            <div>
              <label htmlFor="first-name" className="block text-sm font-medium text-white">
                First name
                <div className="mt-1">
                  <input type="text" disabled={state.loading} required name="first-name" id="first-name" autoComplete="given-name" className="disabled:opacity-50 py-3 px-4 block w-full shadow-sm focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md text-black" />
                </div>
              </label>
            </div>
            <div>
              <label htmlFor="last-name" className="block text-sm font-medium text-white">
                Last name
                <div className="mt-1">
                  <input type="text" disabled={state.loading} required name="last-name" id="last-name" autoComplete="family-name" className="disabled:opacity-50 py-3 px-4 block w-full shadow-sm focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md text-black" />
                </div>
              </label>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="email" className="block text-sm font-medium text-white">
                Email
                <div className="mt-1">
                  <input id="email" disabled={state.loading} required name="email" type="email" autoComplete="email" className="disabled:opacity-50 py-3 px-4 block w-full shadow-sm focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md text-black" />
                </div>
              </label>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="message" className="block text-sm font-medium text-white">
                Message
                <div className="mt-1">
                  <textarea id="message" disabled={state.loading} required name="message" rows={4} className="disabled:opacity-50 py-3 px-4 block w-full shadow-sm focus:ring-orange-500 focus:border-orange-500 border border-gray-300 rounded-md text-black" />
                </div>
              </label>
            </div>
            <div className="sm:col-span-2">
              <button disabled={state.loading} type="submit" className="disabled:opacity-50 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-orange-700 hover:bg-whtext-white hover:bg-orange-800 focus:bg-orange-800">
                Let&apos;s talk
              </button>
            </div>
            <RenderIf condition={state.success}>
              <div className="sm:col-span-2 italic text-white">
                &rarr; Your submission has been received.
              </div>
            </RenderIf>
            <RenderIf condition={state.error !== null}>
              <div className="sm:col-span-2 italic text-white">
                &rarr; Uh oh! We didn&apos;t get that one. Please try again.
              </div>
            </RenderIf>
          </form>
        </div>
      </div>
    </div>
  )
}

export const paragraphContactFormFragments = graphql`
  fragment ParagraphContactFormFields on paragraph__form_contact {
    __typename
    id
  }
`

export default ContactForm
