import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Link from '../../Link'
// @ts-ignore
import * as styles from './styles.module.css'

enum TeamRole {
  CEO = 'ceo',
  DIRECTOR_OF_TECHNOLOGY = 'director_of_technology',
  SENIOR_ENGINEER = 'senior_engineer',
  ENGINEER = 'engineer',
  SENIOR_DESIGNER = 'senior_designer',
  DESIGNER = 'designer',
  PROJECT_MANAGER = 'project_manager',
  TEAM_LEADER = 'team_leader',
}

const TeamRoleLabel = {
  [TeamRole.CEO]: 'Chief Executive Officer',
  [TeamRole.DIRECTOR_OF_TECHNOLOGY]: 'Director of Technology',
  [TeamRole.SENIOR_ENGINEER]: 'Senior Engineer',
  [TeamRole.ENGINEER]: 'Software Engineer',
  [TeamRole.SENIOR_DESIGNER]: 'Senior Designer',
  [TeamRole.DESIGNER]: 'Designer',
  [TeamRole.PROJECT_MANAGER]: 'Project Manager',
  [TeamRole.TEAM_LEADER]: 'Team Leader',
}

interface TeamListingItemProps {
  title: string
  field_team_role: TeamRole
  path: { alias: string }
  relationships: {
    field_avatar?: {
      gatsbyImageData: any,
    }
  }
}

const TeamListingItem = ({
  title,
  field_team_role,
  relationships,
  path,
}: TeamListingItemProps) => (
  <li>
    <Link to={path?.alias}>
      <div className="flex items-center space-x-4 lg:space-x-6">
        <GatsbyImage className={`flex-shrink-0 w-16 h-16 rounded-full lg:w-20 lg:h-20 ${styles.imgContainer}`} imgClassName={`${styles.imgCard}`} image={relationships.field_avatar.gatsbyImageData} alt="" />
        <div className="font-medium text-lg leading-6 space-y-1">
          <div className="text-gray-300">{title}</div>
          <div className="text-white">{TeamRoleLabel[field_team_role]}</div>
        </div>
      </div>
    </Link>
  </li>
)

interface TeamListingProps {
  relationships: {
    field_team_member: Array<{ drupal_id: string } & TeamListingItemProps>
  }
}

const TeamListing: React.FC<TeamListingProps> = ({ relationships }: TeamListingProps) => (
  <div>
    <div className="mx-auto px-4 max-w-7xl sm:px-6 lg:px-8">
      <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8">
        <div className="space-y-5 sm:space-y-4">
          <h2 className="font-serif text-3xl font-extrabold tracking-tight sm:text-4xl text-white">Meet our team</h2>
        </div>
        <div className="lg:col-span-2">
          <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0 lg:gap-x-8">
            {relationships?.field_team_member?.map((teamMember) => (
              <TeamListingItem key={teamMember.drupal_id} {...teamMember} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  </div>
)

export const paragraphTeamListingFragments = graphql`
  fragment ParagraphTeamListingFields on paragraph__team_listing {
    __typename
    id
    relationships {
      field_team_member {
        drupal_id
        title
        field_team_role
        path {
          alias
        }
        relationships {
          field_avatar {
            gatsbyImageData(width: 160, aspectRatio: 1, placeholder: BLURRED)
          }
        }
      }
    }
  }
`

export default TeamListing
