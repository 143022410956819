import React from 'react'
import { graphql } from 'gatsby'

interface PageTitleProps {
  field_title: string
}

const PageTitle: React.FC<PageTitleProps> = ({
  field_title,
}: PageTitleProps) => (
  <h1 className="text-white text-center font-serif text-3xl font-extrabold tracking-tight sm:text-4xl">{field_title}</h1>
)

export const paragraphPageTitleFragments = graphql`
  fragment ParagraphPageTitleFields on paragraph__page_title {
    __typename
    id
    field_title
  }
`

export default PageTitle
