import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ContentListing from '../../ContentListing'

interface CaseStudiesListingParagraphProps {
  field_title: string
  field_subtitle: string
}

const CaseStudiesListingParagraph: React.FC<CaseStudiesListingParagraphProps> = ({
  field_title,
  field_subtitle,
}: CaseStudiesListingParagraphProps) => {
  const data = useStaticQuery(graphql`
    {
      allNodeCaseStudy(sort: { fields: [created], order: DESC }) {
        nodes {
          __typename
          drupal_id
          created
          title
          path {
            alias
          }
          body {
            time_to_read
            summary
          }
          relationships {
            field_topic {
              name
            }
            field_author {
              title
              path {
                alias
              }
              relationships {
                field_avatar {
                  gatsbyImageData(width: 40, aspectRatio: 1, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <ContentListing
      title={field_title}
      subtitle={field_subtitle}
      items={data?.allNodeCaseStudy?.nodes}
    />
  )
}

export default CaseStudiesListingParagraph

export const paragraphCaseStudiesListingFragments = graphql`
  fragment ParagraphCaseStudiesListingFields on paragraph__case_studies_listing {
    __typename
    id
    field_title
    field_subtitle
  }
`
