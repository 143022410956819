import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import RenderIf from '../RenderIf'

const shortMonth = (month: number) => ([
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
][month])

interface ContentListingItemProps {
  title: string
  created: string
  path: { alias: string }
  body: {
    summary: string
    time_to_read: string
  }
  relationships: {
    field_topic: {
      name: string
    }
    field_author: {
      title: string
      path: {
        alias: string
      }
      relationships: {
        field_avatar: {
          gatsbyImageData: any
        }
      }
    }
  }
}

const ContentListingItem = ({
  title,
  created,
  path,
  body,
  relationships,
}: ContentListingItemProps) => {
  const createdDate = new Date(created)

  return (
    <div>
      <div>
        <RenderIf condition={!!relationships?.field_topic?.name}>
          <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
            {relationships?.field_topic?.name}
          </span>
        </RenderIf>
      </div>
      <Link to={path?.alias} className="block mt-4">
        <h3 className="font-serif text-xl font-semibold text-white">
          {title}
        </h3>
        <p className="mt-3 text-base text-gray-300 ">
          {body?.summary}
        </p>
      </Link>
      <Link to={relationships?.field_author?.path?.alias}>
        <div className="mt-6 flex items-center">
          <div className="flex-shrink-0">
            <span className="sr-only">{relationships?.field_author?.title}</span>
            <GatsbyImage className="h-10 w-10 rounded-full" image={relationships?.field_author?.relationships?.field_avatar?.gatsbyImageData} alt="" />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-white">
              {relationships?.field_author?.title}
            </p>
            <div className="flex space-x-1 text-sm text-white">
              <time dateTime={`${createdDate.getFullYear()}-${createdDate.getMonth() + 1}-${createdDate.getDate()}`}>
                {shortMonth(createdDate.getMonth())}
                {' '}
                {createdDate.getDate()}
                ,
                {' '}
                {createdDate.getFullYear()}
              </time>
              <span aria-hidden="true">
                &middot;
              </span>
              <span>
                {body.time_to_read}
                {' '}
                min read
              </span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

interface ContentListingProps {
  title?: string
  subtitle?: string
  items: any[],
}

const ContentListing: React.FC<ContentListingProps> = ({
  title = 'Recent posts',
  subtitle = 'Our team shares their latest industry experiences through the posts below.',
  items,
}: ContentListingProps) => (
  <div>
    <div className="max-w-lg mx-auto lg:max-w-7xl">
      <div className="relative px-4 sm:px-6 lg:px-8 divide-y-2 divide-gray-200">
        <div>
          <h2 className="font-serif text-3xl tracking-tight font-extrabold text-white sm:text-4xl">
            {title}
          </h2>
          <p className="mt-3 text-xl text-gray-300 sm:mt-4">
            {subtitle}
          </p>
        </div>
        <div className="mt-6 grid gap-16 pt-6 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
          {items?.map((contentNode) => (
            <ContentListingItem
              key={contentNode.drupal_id}
              {...contentNode}
            />
          ))}
        </div>
      </div>
    </div>
  </div>
)

export default ContentListing
