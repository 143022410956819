import React from 'react'
import { graphql } from 'gatsby'

interface ClientListingItemProps {
  title: string
  relationships: {
    field_logo: {
      relationships: {
        field_media_image: {
          uri: {
            url: string
          }
        }
      }
    }
  }
}

const ClientListingItem = ({ title, relationships }: ClientListingItemProps) => (
  <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-900">
    <img alt={`${title} logo`} style={{ minHeight: 48 }} className="max-h-12" src={`https://cms.desarol.com${relationships?.field_logo?.relationships?.field_media_image?.uri?.url}`} />
  </div>
)

interface ClientListingProps {
  relationships: {
    field_clients: Array<ClientListingItemProps & { drupal_id: string }>
  }
}

const ClientListing: React.FC<ClientListingProps> = ({ relationships }: ClientListingProps) => (
  <div>
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-6">
      <p className="font-serif text-center text-base font-semibold uppercase text-gray-300 tracking-wider">
        Trusted by clients big and small
      </p>
      <div className="mt-6 grid grid-cols-1 gap-3  md:grid-cols-3 lg:mt-8">
        {relationships?.field_clients.map((client) => (
          <ClientListingItem key={client.drupal_id} {...client} />
        ))}
      </div>
    </div>
  </div>
)

export const paragraphClientListingFragments = graphql`
  fragment ParagraphClientListingFields on paragraph__client_listing {
    __typename
    id
    relationships {
      field_clients {
        drupal_id
        title
        relationships {
          field_logo {
            relationships {
              field_media_image {
                uri {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ClientListing
