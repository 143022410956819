import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ContentListing from '../../ContentListing'

interface BlogListingsParagraphProps {
  field_title: string
  field_subtitle: string
}

const BlogsListingParagraph: React.FC<BlogListingsParagraphProps> = (
  { field_title, field_subtitle }: BlogListingsParagraphProps,
) => {
  const data = useStaticQuery(graphql`
    {
      allNodeBlog(sort: { fields: [created], order: DESC }) {
        nodes {
          __typename
          drupal_id
          created
          title
          path {
            alias
          }
          body {
            time_to_read
            summary
          }
          relationships {
            field_topic {
              name
            }
            field_author {
              title
              path {
                alias
              }
              relationships {
                field_avatar {
                  gatsbyImageData(width: 40, aspectRatio: 1, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <ContentListing
      title={field_title}
      subtitle={field_subtitle}
      items={data?.allNodeBlog?.nodes}
    />
  )
}

export default BlogsListingParagraph

export const paragraphBlogsListingFragments = graphql`
  fragment ParagraphBlogsListingFields on paragraph__blogs_listing {
    __typename
    id
    field_title
    field_subtitle
  }
`
