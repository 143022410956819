/* eslint-disable max-len */
import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import RenderIf from '../RenderIf'
// @ts-ignore
import * as styles from './styles.module.css'

const shortMonth = (month: number) => ([
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
][month])

interface CardListingItemProps {
  title: string
  created: string
  path: { alias: string }
  body: {
    summary: string
    time_to_read: string
  }
  relationships: {
    field_topic: {
      name: string
    }
    field_author: {
      title: string
      path: {
        alias: string
      }
      relationships: {
        field_avatar: {
          gatsbyImageData: any
        }
      }
    }
  }
}

const CardListingItem = ({
  title,
  created,
  path,
  body,
  relationships,
}: CardListingItemProps) => {
  const createdDate = new Date(created)

  return (
    <div className={`${styles.miniCard} ${styles.miniCardBorder} relative flex flex-col p-5 rounded-2xl shadow-xl justify-between z-20`}>
      <div className="relative z-10">
        <div>
          <RenderIf condition={!!relationships?.field_topic?.name}>
            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
              {relationships?.field_topic?.name}
            </span>
          </RenderIf>
        </div>
        <Link to={path?.alias} className="block mt-4 pr-5 md:pr-0">
          <h3 className="font-serif text-xl font-semibold text-white">
            {title}
          </h3>
          <p className="mt-3 text-base text-gray-300">
            {body?.summary.substring(0, 100)}
            ...
          </p>
        </Link>
      </div>
      <Link to={relationships?.field_author?.path?.alias}>
        <div className="mt-6 flex items-center relative z-20">
          <div className="flex-shrink-0">
            <span className="sr-only">{relationships?.field_author?.title}</span>
            <GatsbyImage className={`h-10 w-10 rounded-full ${styles.imgContainer}`} imgClassName={`${styles.imgCard}`} image={relationships?.field_author?.relationships?.field_avatar?.gatsbyImageData} alt="" />
          </div>
          <div className="ml-3 text-white">
            <p className="text-sm font-medium">
              {relationships?.field_author?.title}
            </p>
            <div className="flex space-x-1 text-sm">
              <time dateTime={`${createdDate.getFullYear()}-${createdDate.getMonth() + 1}-${createdDate.getDate()}`}>
                {shortMonth(createdDate.getMonth())}
                {' '}
                {createdDate.getDate()}
                ,
                {' '}
                {createdDate.getFullYear()}
              </time>
              <span aria-hidden="true">
                &middot;
              </span>
              <span>
                {body.time_to_read}
                {' '}
                min read
              </span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

interface CardsListingProps {
  title?: string
  subtitle?: string
  items: any[],
}

const CardsListing: React.FC<CardsListingProps> = ({
  title = 'Recent posts',
  subtitle = 'Our team shares their latest industry experiences through the posts below.',
  items,
}: CardsListingProps) => (
  <div>
    <div className="mx-auto lg:max-w-7xl">
      <div className="mt-8 ml-2 py-2 px-3 md:py-4 md:px-5 rounded-2xl">
        <div className="relative z-10">
          <h2 className="font-serif text-3xl text-white tracking-tight font-extrabold sm:text-4xl">
            {title}
          </h2>
          <p className="my-3  text-xl text-gray-300 sm:mt-4">
            {subtitle}
          </p>
          <Link to="/blog" className="text-xl text-gray-300 sm:mt-4 underline">
            Read all posts by Desarol
          </Link>
        </div>
      </div>
      <div className="pr-5 md:pr-7">
        <div className={`px-3 md:px-0 flex overflow-x-scroll md:overflow-x-hidden py-5 relative ${styles.borderCardContainer}`}>
          <div className="flex pl-9 pt-8 pb-5 relative md:overflow-x-scroll">
            <div className={`sticky w-8 md:hidden top-8 ${styles.mobileBar} z-50`} />
            {items?.map((contentNode) => (
              <CardListingItem
                key={contentNode.drupal_id}
                {...contentNode}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default CardsListing
