/* eslint-disable react/jsx-indent */
import React from 'react'

import HeroComponent from './Hero'
import ClientListingComponent from './ClientListing'
import TeamListingComponent from './TeamListing'
import ContentListingComponent from './ContentListing'
import BlogsListingComponent from './BlogsListing'
import ContactFormComponent from './ContactForm'
import CaseStudiesListingComponent from './CaseStudiesListing'
import ContentComponent from './Content'
import ServicesListingComponent from './ServicesListing'
import CardsListingComponent from './CardsListing'
import PageTitleComponent from './PageTitle'

enum ParagraphType {
  HERO = 'paragraph__hero',
  CLIENT_LISTING = 'paragraph__client_listing',
  TEAM_LISTING = 'paragraph__team_listing',
  CONTENT_LISTING = 'paragraph__content_listing',
  BLOGS_LISTING = 'paragraph__blogs_listing',
  CASE_STUDIES_LISTING = 'paragraph__case_studies_listing',
  FORM_CONTACT = 'paragraph__form_contact',
  CONTENT = 'paragraph__content',
  SERVICES_LISTING = 'paragraph__service_listing',
  CARDS_LISTING = 'paragraph__cards_listing',
  PAGE_TITLE = 'paragraph__page_title',
}

const ParagraphComponent = {
  [ParagraphType.HERO]: HeroComponent,
  [ParagraphType.CLIENT_LISTING]: ClientListingComponent,
  [ParagraphType.TEAM_LISTING]: TeamListingComponent,
  [ParagraphType.CONTENT_LISTING]: ContentListingComponent,
  [ParagraphType.BLOGS_LISTING]: BlogsListingComponent,
  [ParagraphType.CASE_STUDIES_LISTING]: CaseStudiesListingComponent,
  [ParagraphType.FORM_CONTACT]: ContactFormComponent,
  [ParagraphType.CONTENT]: ContentComponent,
  [ParagraphType.SERVICES_LISTING]: ServicesListingComponent,
  [ParagraphType.CARDS_LISTING]: CardsListingComponent,
  [ParagraphType.PAGE_TITLE]: PageTitleComponent,
}

type ParagraphProps = React.PropsWithChildren<{
  __typename: ParagraphType
  id: string
}>

const Paragraph = ({ __typename: typename, ...rest }: ParagraphProps) => {
  const Component: React.FC<any> = ParagraphComponent[typename]

  // Component is not defined for this paragraph type.
  if (!Component) return null

  return <Component {...rest} />
}

type ParagraphsProps = React.PropsWithChildren<{
  paragraphs: ParagraphProps[]
}>

const Paragraphs: React.FC<ParagraphsProps> = ({ paragraphs }: ParagraphsProps) => (
  <>
    {(paragraphs.map((paragraph) => {
      const {
        id: paragraphId,
        __typename: typename,
        ...rest
      } = paragraph

      return (
        <div>
          <Paragraph
            key={paragraphId}
            __typename={typename}
            id={paragraphId}
            {...rest}
          />
          <div className="clear-both my-10" />
        </div>
      )
    }))}
  </>
)

export default Paragraphs
