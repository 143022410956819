/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Paragraphs from '../../components/Paragraph'

interface PageProps {
  data?: {
    nodePage?: {
      title: string
      relationships: {
        field_paragraphs: any[]
      }
    }
  }
}

const Page: React.FC<PageProps> = ({ data }: PageProps) => {
  const node = data?.nodePage

  useEffect(() => {
    document.querySelectorAll('h1').forEach((el) => {
      el.style.color = 'white'
    })

    document.querySelectorAll('h2').forEach((el) => {
      el.style.color = 'white'
    })

    document.querySelectorAll('h3').forEach((el) => {
      el.style.color = 'white'
    })
  }, [])

  return (
    <Layout title={node.title}>
      <Paragraphs paragraphs={node.relationships?.field_paragraphs} />
    </Layout>
  )
}

export const PageQuery = graphql`
  query PageQuery($drupal_internal__nid: Int!) {
    nodePage(drupal_internal__nid: {eq: $drupal_internal__nid}) {
      title
      relationships {
        field_paragraphs {
          ...on paragraph__hero {
            ...ParagraphHeroFields
          }
          ...on paragraph__client_listing {
            ...ParagraphClientListingFields
          }
          ...on paragraph__service_listing {
            ...ParagraphServicesListingFields
          }
          ...on paragraph__team_listing {
            ...ParagraphTeamListingFields
          }
          # ...on paragraph__content_listing {
          #   ...ParagraphContentListingFields
          # }
          ...on paragraph__blogs_listing {
            ...ParagraphBlogsListingFields
          }
          ...on paragraph__case_studies_listing {
            ...ParagraphCaseStudiesListingFields
          }
          ...on paragraph__form_contact {
            ...ParagraphContactFormFields
          }
          ...on paragraph__content {
            ...ParagraphContentFields
          }
          ... on paragraph__cards_listing {
            ...ParagraphCardsListingFields
          }
          ... on paragraph__page_title {
            ...ParagraphPageTitleFields 
          }
        }
      }
    }
  }
`

export default Page
