import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'

export const paragraphServicesListingFragments = graphql`
  fragment ParagraphServicesListingFields on paragraph__service_listing {
    __typename
    id
    field_title
    field_subtitle
  }
`
interface ServicestListingParagraphProps {
  field_title: string
  field_subtitle: string
}

const ServicesListingParagraph: React.FC<ServicestListingParagraphProps> = (
  {
    field_title,
    field_subtitle,
  }: ServicestListingParagraphProps,
) => {
  const data = useStaticQuery(graphql`
    {
      allNodeService {
        nodes {
          drupal_id
          title
          path {
            alias
          }
          body {
            summary
          }
          relationships {
            field_logo {
              relationships {
                field_media_image {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const services = data?.allNodeService?.nodes

  return (
    <div>
      <div className="max-w-lg mx-auto lg:max-w-7xl">
        <div className="relative px-4 sm:px-6 lg:px-8 divide-y-2 divide-gray-200">
          <div>
            <h2 className="text-3xl tracking-tight font-extrabold text-white sm:text-4xl">
              {field_title}
            </h2>
            <p className="mt-3 mb-12 text-xl text-gray-300 sm:mt-4">
              {field_subtitle}
            </p>
          </div>
          <dl className="pt-12 space-y-10 sm:space-y-0 grid lg:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 ">
            {services.map((service: any) => {
              // eslint-disable-next-line max-len
              const image = service?.relationships?.field_logo?.relationships?.field_media_image?.uri?.url
              return (
                <Link to={service?.path?.alias} key={service?.drupal_id}>
                  <dt>
                    <div className="flex items-center justify-center h-12 w-12 rounded-md  text-white">
                      <img src={`https://cms.desarol.com/${image}`} alt="ahag" />
                    </div>
                    <p className="mt-5 text-lg leading-6 font-medium text-white">{service?.title}</p>
                  </dt>
                  <dd className="mt-2 text-base text-gray-300">{service?.body?.summary}</dd>
                </Link>
              )
            })}
          </dl>
        </div>
      </div>
    </div>
  )
}

export default ServicesListingParagraph
